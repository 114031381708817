<template>
  <div class="main">
    <ul class="ulform">
      <li class="ul-from-item">
        <div class="left">
          <img src="@/assets/phone-icon.png">
          <div class="text">
            <div>支付密码</div>
            <div></div>
          </div>
        </div>
        <div class="right" @click="fn1">修改</div>
      </li>
      <li class="ul-from-item">
        <div class="left">
          <img src="@/assets/phone-icon.png">
          <div class="text">
            <div>银行卡</div>
            <div>
              {{bankcard}}
            </div>
          </div>
        </div>
        <router-link style="text-decoration: none;" :to="'/setbankcard/Msmchecking/' + isIdentity"  >
          <div class="right">{{title}}</div>
        </router-link>
      </li>
    </ul>
    <passwordBox ref="tablebox"></passwordBox>
    <PurPasswordBox ref="istable"></PurPasswordBox>
  </div>
</template>

<script>
import passwordBox from '../../components/PasswordBox.vue'
import PurPasswordBox from '../../components/PurPasswordBox.vue'
export default {
  name:'PaymentSettings',
  components: {
    passwordBox,
    PurPasswordBox,
  },
  computed: {
    userInfo() {
      return this.$store.state.usermsg
    },
    getPhone() {
      let phone = this.userInfo.mobile || ''
      return phone.replace(phone.substring(4,7),'****')
    },
    isIdentity(){
      //返回当前身份状态 0 用户 1机构
      return this.$route.meta?.personal ? 0 : 1
    }

  },
  created() {
    //登录机构返回机构银行卡数据 多机构版本--type: 0机构 1用户
    this.$post('/api/wallet/myBankMsg?type='+(this.isIdentity ? 0 : 1)).then((res) => {
      if (res.data == null) {
        this.bankcard = '未绑定'
        this.alterReve = true
        this.title = '新增'
      } else {
        if (res.data.status == 0) {
          this.title = '审核中'
        } else if (
            res.data.status == 2
        ) {
          this.title = '审核失败'
        } else {
          this.title = '修改'
        }
        let bacd = res.data.bankCard.substring(res.data.bankCard.length - 4, res.data.bankCard.length)
        this.bankcard = `${res.data.bankName}(${bacd})`
      }
    })
    },
  data () {
    return {
      bankcard:'',
      title:''
    }
  },

  methods: {
    fn () {
      this.$refs.tablebox.changePassword(this.getPhone, this.data)
    },
    fn1 () {
      this.$refs.istable.changePassword(this.getPhone, this.data)
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  @mixin publicPadding{
    padding: {
      top:37px;
      bottom: 20px;
    };
    .title{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #333333;
    }
    .subtitle{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #666666;
    }
    .input-box{
      margin-top: 20px;
      ::v-deep .el-input{
        .el-input__inner{
          border: none;
          border-bottom: 1px solid #e8e8e8;
        }
        .el-input__suffix{
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #ff004d;
          cursor: pointer;
        }
      }

    }
  }
  .ulform {
    padding: 0;
    list-style: none;
    .ul-from-item{
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .left {
        display: flex;
        align-items: center;
        width: 300px;
        >img{
          margin-right: 16px;
          width: 48px;
          height: 48px;
        }
        .text{
          div:first-child{
            margin-bottom: 8px;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
          }
          div{
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
          }
        }
      }
      .right{
        font-size: 14px;
        font-weight: 400;
        text-align: LEFT;
        color: #ff004d;
        cursor: pointer;
      }
    }
  }
  .content{
    .but-footer{
      text-align: center;
      margin-top: 37px;
      .el-button{
        width: 160px;
        height: 40px;
        background: #f4f4f4;
        border-radius: 3px;
        margin: 0 9px;
        &.but-active{
          background: #ff004d;
          color: #ffffff;
        }
      }
    }
    .set-phone-number-step-1{
      @include publicPadding
    }
    .set-phone-number-step-2{
      @include publicPadding
    }
    .set-phone-number-step-3{
      @include publicPadding;
      .subtitle{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
      .input-box{
        ::v-deep .el-input-group__prepend{
          background: #FFFFFF;
          border: none;
          border-bottom: 1px solid #e8e8e8;
        }
        ::v-deep .el-select{
          width: 85px;
          box-sizing: border-box;
          .el-input__inner{
            padding: 0 15px;
            box-sizing: border-box;
            border: none;
          }
          .el-input__prefix{
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .set-phone-number-step-4{
      .title{
        margin: 34px 0;
        width: 360px;
        height: 51px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>
